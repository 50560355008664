import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Polyethylen (PE)" description="@todo" mdxType="SEO" />
    <h1 {...{
      "id": "polyethylen-pe"
    }}>{`Polyethylen (PE)`}</h1>
    <p>{`Polyethylen (PE) je termoplast, který vzniká polymerací ethenu. Je odolný vůči kyselinám i zásadám, použitelný do teploty kolem 80 stupňů Celsia. Vyrábí se z něj smrštitelné folie, roury, ozubená kola, ložiska, textilní vlákna, nejrůznější hračky, sáčky (mikroten) a elektrotechnická izolace.`}</p>
    <p>{`Rozlišují se dva základní druhy polyethylenu: PE-LD / `}<strong parentName="p">{`LDPE`}</strong>{` (s nízkou hustotou) a PE-HD / `}<strong parentName="p">{`HDPE`}</strong>{` (s vysokou hustotou). PE-HD má vysoký stupeň krystality, což způsobuje jeho vysokou chemickou odolnost a odolnost proti rozpouštědlům. Podmínkou vysokého stupně krystality je linearita a stereoregularita řetězců, čehož se dosahuje při polyinzerci.`}</p>
    <p>{`PE-HD se používá i při výrobě kompozitního materiálu na bázi dřeva – woodplastic, který se používá jako náhrada dřeva v mnoha oborech.`}</p>
    <p>{`Polyethylen je v současnosti nejpoužívanějším polymerem na světě.`}</p>
    <p>{`Zdroj: `}<a parentName="p" {...{
        "href": "https://cs.wikipedia.org/wiki/Polyethylen",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Polyethylen na Wikipedii`}</a>{`, licence `}<a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by-sa/3.0/deed.cs",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`CC BY-SA 3.0`}</a></p>
    <hr />
    <a href="javascript:history.back()">Zpět</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      